import styled from 'styled-components'
import { transparentize } from 'polished'

export const Blockquote = styled.blockquote`
  background: ghostwhite;
  margin: 0 0 2rem;
  padding: 2rem;
  border-left: 1rem solid ${transparentize(0.6, 'purple')};
  font-style: italic;

  p:last-child {
    margin-bottom: 0;
  }
`

export const IframeContainer = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`
